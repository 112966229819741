<template>
  <div class="d-flex align-center flex-nowrap notes-filter-bar-wrapper">
    <ClearFilterBtn
      :isEmpty="isFilterEmpty"
      :count="notesCount"
      @clear-btn:click="clearListFiltersList"
    />

    <v-btn-toggle
      v-for="filter in createdFiltersMap"
      :key="filter.hash"
      :label="filter.title"
      multiple
      borderless
      class="pt-0 filter-toggle-btns notes-filters"
      :class="filter.title + '-filters'"
    >
      <template>
        <v-tooltip
          bottom
          v-for="filterItem in filter.data"
          :key="filterItem.order"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :class="createCategoryBtnStyles(filterItem)"
              v-on:click="selectFilter(filterItem, $event)"
              :data-filter-cate-id="filterItem.key"
              x-small
            >
              {{ filterItem.title }}
            </v-btn>
          </template>
          <span> Filter: {{ filterItem.title }} </span>
        </v-tooltip>
      </template>
    </v-btn-toggle>
  </div>
</template>
<script>
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import ClearFilterBtn from "@/components/ClearFilterBtn";
import { mapActions, mapGetters } from "vuex";
import { getRootTopicsFromList, getUserNotes } from "@/helpers/notes";
import blueimpMD5 from "blueimp-md5";
import cloneDeep from "lodash/cloneDeep";
import { LIGHT_GREY_BG_CLASSNAME } from "@/variables/colors";
export default {
  components: {
    ClearFilterBtn,
  },
  data() {
    return {
      createdFiltersMap: {},
    };
  },
  methods: {
    ...mapActions(["updateRootState"]),
    ...mapActions("note", [
      "setNoteTopicsSelection",
      "clearNoteTopicsSelection",
    ]),
    clearListFiltersList() {
      const catTree =
        document.querySelector("#directory-tree")?.ej2_instances[0];
      this.clearNoteTopicsSelection();
      this.updateRootState({
        currViewData: {},
        itemInspectEnabled: false,
      });
      getUserNotes().set([], "selectedFolders");
      if (catTree) {
        catTree.collapseAll();
      }
    },

    getRootTopicsBySelectionList(selectedIDs) {
      return getRootTopicsFromList(selectedIDs, this.directories);
    },
    checkSelectedFoldersForRoot(selectedIds) {
      return selectedIds && selectedIds.some((k) => k === "root");
    },
    checkIfTopicIsActive(topicKey) {
      const selectedTopicList = this.selectedNoteTopics;
      let isSelected = false;
      if (!isEmpty(selectedTopicList)) {
        isSelected = selectedTopicList.includes(topicKey);
      }

      if (
        !isSelected &&
        !isEmpty(this.selectedFolders) &&
        !this.checkSelectedFoldersForRoot(this.selectedFolders)
      ) {
        isSelected = this.getRootTopicsBySelectionList(
          this.selectedFolders
        ).includes(topicKey);
      }

      return isSelected;
    },
    createCategoryBtnStyles(filterData) {
      const isSelected = this.checkIfTopicIsActive(filterData.key);
      return {
        "note-filter-btn": true,
        [LIGHT_GREY_BG_CLASSNAME]: !isSelected,
        "selected-filter": isSelected,
      };
    },
    selectFilter(filterData, event) {
      const isAltKeyPressed = event && event.altKey;

      let topicList = [];

      const currSelectedFolders = this.selectedFolders;
      const currActiveTopicList = !isEmpty(this.selectedNoteTopics)
        ? cloneDeep(this.selectedNoteTopics)
        : [];

      if (!isEmpty(currSelectedFolders) && isEmpty(currActiveTopicList)) {
        topicList = this.getRootTopicsBySelectionList(currSelectedFolders);
      }

      topicList = Array.from(new Set([...currActiveTopicList, ...topicList]));

      if (
        isAltKeyPressed &&
        !isEmpty(this.createdFiltersMap.topic.data) &&
        this.createdFiltersMap.topic.data.length > 1
      ) {
        let listToUse =
          cloneDeep(!isEmpty(topicList) ? topicList : currActiveTopicList) ||
          [];

        if (listToUse.includes(filterData.key)) {
          listToUse.splice(listToUse.indexOf(filterData.key), 1);
        } else {
          const allCategories = this.createdFiltersMap.topic.data;
          listToUse = allCategories.reduce((accu, cate) => {
            if (cate.key !== filterData.key) {
              accu.push(cate.key);
            }
            return accu;
          }, []);
        }

        topicList = [...listToUse];
      } else {
        if (topicList.includes(filterData.key)) {
          topicList.splice(topicList.indexOf(filterData.key), 1);
        } else {
          topicList.push(filterData.key);
        }
      }

      this.setNoteTopicsSelection(topicList);

      this.updateRootState({
        currViewData: {},
        itemInspectEnabled: false,
      });
    },
  },
  computed: {
    ...mapGetters("note", [
      "notesCount",
      "selectedFolders",
      "selectedNoteTopics",
      "notesTree",
      "directories",
    ]),
    //     ...mapGetters("note", [
    //   "notes",
    //   "directories",
    //   "notesTree",
    //   "selectedFolders",
    //   "dirChildrenMap",
    //   "rawNotes",
    // ]),
    isFilterEmpty() {
      return isEmpty(this.selectedFolders);
    },
    filterBtnIcon() {
      return this.isFilterEmpty ? "$filters_cleared" : "$filters_enabled";
    },
    filtersListMap() {
      const filtersMap = {
        topic: {
          title: "topic",
          data: [],
        },
      };

      const ignoreKeys = ["root"];

      let topicFiltersList = [];

      this.notesTree.forEach((topicData) => {
        if (!ignoreKeys.includes(topicData.key)) {
          const topicFilter = {
            ...topicData,
            key: topicData.key,
            title: topicData.name,
            icon: "",
            iconSize: 13,
            order: topicData.order,
            color: "#7c7c7c",
            type: "topic",
            children: [],
          };
          topicFiltersList.push(topicFilter);
        }
      });
      if (
        !isEmpty(this.selectedFolders) &&
        this.selectedFolders[0] !== "root"
      ) {
        const selectedCategories = this.getRootTopicsBySelectionList(
          this.selectedFolders
        );
        topicFiltersList = topicFiltersList.filter(
          (resource) => selectedCategories.indexOf(resource.key) > -1
        );
      }

      filtersMap.topic.data = topicFiltersList;
      filtersMap.topic.hash = !isEmpty(filtersMap.topic.data)
        ? blueimpMD5(JSON.stringify(filtersMap.topic.data))
        : "topics";

      return Object.freeze(filtersMap);
    },
  },
  watch: {
    filtersListMap: {
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.createdFiltersMap = n;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped lang="scss">
.notes-filter-bar-wrapper {
  margin-top: -10px;
  padding-top: 10px;
  padding-bottom: 10px;
  .v-btn-toggle > .v-btn.v-btn.filter-btn {
    opacity: 1 !important;
  }
  gap: 8px;
}

.notes-filters.v-btn-toggle > .v-btn:last-child {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.notes-filters.v-btn-toggle > .v-btn.v-btn:last-of-type {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.notes-filters {
  margin: 0px;
  border-radius: 6px !important;

  .note-filter-btn {
    color: #403a31 !important;
    font-size: 0.692rem !important;
    line-height: 11px !important;
    padding: 5px 8px !important;
    border-radius: 0px;

    &.selected-filter {
      background: var(--primary-color) !important;
      color: #fff !important;
    }
  }
  .filter-text {
    font-size: 12px;
  }
}
</style>
