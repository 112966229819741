<template>
  <span :class="data.type">
    <span class="data-container" :data-dir-id="data.key">
      <span>
        {{ data.name }}
      </span>

      <EditTreeNodeBtn
        :itemCount="count"
        menuId="directory-menu"
        :nodeId="data.key"
        preventEvent
      />
    </span>
  </span>
</template>

<style scoped>
.data-container {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 16px;
  align-items: center;
  min-height: 25px;
  padding-right: 10px;
}

.data-container:hover >>> .edit-btn-wrapper {
  display: inline-block;
}
</style>
<script>
import EditTreeNodeBtn from "@/components/EditTreeNodeBtn";

export default {
  data() {
    return {
      data: {},
    };
  },
  components: {
    EditTreeNodeBtn,
  },
  methods: {
    handleNodeEdit(data) {
      const { element } = data;
      const contextEl = document.querySelector(".e-contextmenu-wrapper")
        .childNodes[0];
      const positions = element.getBoundingClientRect();
      if (contextEl?.id === "directory-menu") {
        const contextInstance = contextEl?.ej2_instances[0];
        if (contextInstance) {
          contextInstance.open(positions.top, positions.left, element);
        }
      }
    },
  },
  computed: {
    count() {
      return this.data.totalCount ? this.data.totalCount : null;
    },
  },
};
</script>
