<template>
  <div class="fill-height">
    <!-- <div v-show="isEditFormOpen">
    </div> -->
    <div class="fill-height notes-table-wrapper pl-4">
      <!-- <div v-show="isEditFormOpen">
        <NoteEditForm />
      </div> -->
      <NotesTable v-if="showTable" ref="grid" />
    </div>

    <!-- <div v-show="isResourceInfoViewOpen">
      <ResourceInfoEditor sendRefreshEvent />
    </div> -->
    <OptSelectorDialog />
    <ResourceInfoPopUp
      ref="resourcePopUp"
      :isVisible="showMentionInfo"
      :resourceData="mentionInfo"
      :showNotFoundError="showNoMentionFoundError"
    />
  </div>
</template>

<script>
import NotesTable from "@/components/NotesList";
import {
  getUserActions,
  ACTION_ON_TYPES,
  TASK_ACTIONS,
} from "@/helpers/actionHistory";
import { isEscKey, isRedoHotKeys, isUndoHotKeys } from "@/utils/hotKeys";
import notesUndoRedoHelpersMixin from "@/mixins/notesUndoAndRedoHelpersMixin";
import directoriesUndoRedoHelpersMixin from "@/mixins/directoryUndoAndRedoHelpers";
import notesHelpersMixin from "@/mixins/notesHelpersMixin";
import mentionsHelpersMixin from "@/mixins/mentionsHelpers";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import EventEmitter from "@/helpers/eventEmitter";
import { UNDO_EVENT, REDO_EVENT } from "@/variables/events";
import { mapActions, mapGetters } from "vuex";
import OptSelectorDialog from "@/components/OptSelectorDialog";
import ResourceInfoPopUp from "@/components/ResourceInfoPopUp";
import ResourceInfoEditor from "@/components/ResourceInfoEditor";
import NoteEditForm from "@/components/NoteEditForm";
export default {
  mixins: [
    notesUndoRedoHelpersMixin,
    directoriesUndoRedoHelpersMixin,
    notesHelpersMixin,
    mentionsHelpersMixin,
    tasksHelpersMixin,
  ],
  components: {
    NotesTable,
    OptSelectorDialog,
    ResourceInfoPopUp,
    // eslint-disable-next-line vue/no-unused-components
    ResourceInfoEditor,
    NoteEditForm,
  },
  props: {
    showTable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      render: false,
      loading: true,
    };
  },
  methods: {
    updateNotesInTable(notesToUpdate) {
      if (notesToUpdate && notesToUpdate.length) {
        notesToUpdate = notesToUpdate.map((noteToUpdate) => {
          delete noteToUpdate.changedProps;

          return {
            ...noteToUpdate,
            key: noteToUpdate.key,
          };
        });

        this.$refs.grid.updateNotes(notesToUpdate);
      }
    },
    async handleUndoAction() {
      let actions = getUserActions().getActions();
      let actionInUse = true;
      const currAction = actions[0];
      if (!currAction) {
        this.showToast("No action to undo!");
        return;
      }

      getUserActions().storeActionInHistory({ ...currAction });
      const actionOn = currAction.on;
      switch (actionOn) {
        case ACTION_ON_TYPES.NOTES:
          await this.handleUndoOnNotes(currAction);
          break;
        case ACTION_ON_TYPES.DIRECTORY:
          await this.handleUndoOnDirectory(currAction);
          break;
        case ACTION_ON_TYPES.TASK:
          if (currAction.type === TASK_ACTIONS.CONVERTED_TO_NOTES) {
            await this.handleTasksConvertedToNotes(currAction.data);
          } else {
            actionInUse = false;
          }
          break;
        default:
          actionInUse = false;
        // Do nothing;
      }

      if (actionInUse) {
        getUserActions().removeActionByIndex(0);
      }
    },
    async handleRedoAction() {
      const actions = [...getUserActions().getActionsHistory()];
      let actionInUse = true;
      const currAction = actions[0];

      if (!currAction) {
        this.showToast("No action to redo!");
        return;
      }

      const actionOn = currAction.on;
      switch (actionOn) {
        case ACTION_ON_TYPES.NOTES:
          await this.handleRedoOnNotes(currAction);
          break;
        case ACTION_ON_TYPES.DIRECTORY:
          await this.handleRedoOnDirectory(currAction);
          break;
        case ACTION_ON_TYPES.TASK:
          if (currAction.type === TASK_ACTIONS.CONVERTED_TO_NOTES) {
            await this.handleTasksConvertedToNotes(currAction.data, true);
          } else {
            actionInUse = false;
          }
          break;
        default:
          actionInUse = false;
        // Do nothing;
      }

      if (actionInUse) {
        getUserActions().removeActionFromHistoryByIndex(0);
      }
    },
    async handleRedoActionHotKeys(e) {
      if (!isRedoHotKeys(e)) return;
      await this.handleRedoAction();
    },
    async handleUndoHotKeys(e) {
      if (!isUndoHotKeys(e)) return;
      await this.handleUndoAction();
      // }
    },
    handleEscKey(e) {
      if (!isEscKey(e) || this.isEditFormOpen) return;
      e.stopPropagation?.();
      e.preventDefault?.();

      this.handleNotesViewConfig(this.storedViewData);
    },
    handleHotKeys(e) {
      this.handleUndoHotKeys(e);
      this.handleRedoActionHotKeys(e);
    },
    ...mapActions("toast", ["showToast"]),
  },
  computed: {
    ...mapGetters("note", ["directories", "selectedNotes"]),
    ...mapGetters("editForm", {
      isEditFormOpen: "isOpen",
      editNoteData: "noteData",
    }),
    ...mapGetters("resourceInfo", {
      isResourceInfoViewOpen: "isOpen",
    }),
    ...mapGetters({
      storedViewData: "currViewData",
    }),
  },
  mounted() {
    document.addEventListener("keydown", this.handleHotKeys);
    EventEmitter.on(REDO_EVENT, this.handleRedoAction);
    EventEmitter.on(UNDO_EVENT, this.handleUndoAction);
    this.setMentionListeners();
  },
  beforeDestroy() {
    this.removeMentionListeners();
    document.removeEventListener("keydown", this.handleHotKeys);
    EventEmitter.off(REDO_EVENT, this.handleRedoAction);
    EventEmitter.off(UNDO_EVENT, this.handleUndoAction);
  },
};
</script>
<style scoped>
.notes-table-wrapper {
  padding-top: 10px;
}
.wrapper {
  height: 100%;
}
</style>
