<template>
  <div>
    <SaveBtn v-if="data.addNew" @save="saveNote" @cancel="cancel" />
  </div>
</template>
<script>
import notesHelpersMixin from "@/mixins/notesHelpersMixin";
import { columnPropsMixin } from "@/core/components/DataTable";
import { mapActions, mapGetters } from "vuex";
import { createNoteDataForAdd } from "@/helpers/notes";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import { DARK_GREY_ICON_COLOR } from "@/variables/colors";
import SaveBtn from "@/components/GridComponents/SaveAndCancelBtns.vue";
export default {
  mixins: [notesHelpersMixin, columnPropsMixin, tasksHelpersMixin],
  components: {
    SaveBtn,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("convertModeData", ["setDataForConvertMode"]),
    async saveNote() {
      const currDirectories = this.directories;
      const data = this.cell.getData();
      if (data.itemType && data.itemType === "task") {
        this.setDataForConvertMode({
          dataList: [{ ...data }],
          typeToUse: "task",
        });
        return;
      }
      const noteData = createNoteDataForAdd(
        {
          title: data.title,
          descr: data.descr,
          path: data.path,
        },
        this.notes,
        currDirectories
      );

      this.addMultiNotesInNotesList([noteData]);
      this.cell.api.triggerAddRow(noteData);
    },
    cancel() {
      this.cell.api.removeNewRow();
    },
  },
  computed: {
    ...mapGetters("note", ["directories", "notesTree", "notes"]),
    greyIconColor() {
      return DARK_GREY_ICON_COLOR;
    },
  },
};
</script>
