<template>
  <div class="fill-height note-edit-form">
    <FormLayout
      :showForm="isNoteDataValid"
      :showDivider="false"
      :showLoading="loading"
      editType="Resource"
      :isEditStarted="isEditStarted"
      :currItemIndex="currItemIndex"
      :totalItemCount="totalItemCount"
      @save="submitData"
      @close="closeForm"
      @edit-item:select="handleNoteEditType"
      paginationRowClass="pagination-row"
      paginationBtnIconColor="#515151"
    >
      <template #main-content>
        <div class="task-title-actions-wrapper editable-area">
          <v-row no-gutters align="center" justify="space-between">
            <v-col md="auto" sm="12">
              <div class="subject-selector-wrapper">
                <NoteFolderSelector
                  v-model="newNoteData.path"
                  placeholder="Select Subject"
                />
              </div>
            </v-col>
            <v-col md="auto" sm="12">
              <div>
                <ItemTypeMenu
                  type="note"
                  :value="newNoteData.itemType"
                  @input="changeItemType"
                  ref="noteTypeSelector"
                />
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="mt-3 editable-area">
          <div class="note-title-input-wrapper">
            <!-- <TitleEditor
              :value="newNoteData.title"
              @input="handleTitleChange"
              :refSetter="(refEl) => setRef(refEl, 'titleInput')"
            /> -->
            <DescrEditor
              :value="newNoteData.title"
              :addSpaceOnEnter="true"
              @input="handleTitleChange"
              :refSetter="(refEl) => setRef(refEl, 'titleInput')"
            />
          </div>
        </div>

        <div class="mt-2 descr-editor-wrapper editable-area d-none">
          <DescrEditor
            :value="descrData"
            :addSpaceOnEnter="true"
            @input="handleDescrDataChange"
            :placeholder="editorPlaceholder"
            :refSetter="(refEl) => setRef(refEl, 'editor')"
          />

          <div v-if="areChangesSaved" class="changes-saved-text">
            Changes saved
          </div>
        </div>
        <!-- <CreatedAndModifiedViewer
          :createdText="createdAndModifiedDates.createdDate"
          :modifiedText="createdAndModifiedDates.modifiedDate"
        /> -->
      </template>
    </FormLayout>
  </div>
</template>
<script>
import Vue from "vue";
import FormLayout from "@/layouts/FormLayout.vue";
import NoteFolderSelector from "@/components/EditComponents/NoteFolderSelector";
import CreatedAndModifiedViewer from "@/components/FormComponents/CreatedAndModifiedViewer.vue";
import TitleEditor from "@/components/FormComponents/TitleEditor.vue";
import DescrEditor from "@/components/FormComponents/DescrEditor.vue";
import ItemTypeMenu from "@/components/ItemTypeMenu";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import { convertDateTimeToReadableFormat } from "@/helpers/common";
import { DATE_WITH_TIME_FORMAT_IN_USE } from "@/variables/dates";
import { mapActions, mapGetters } from "vuex";
import notesHelpersMixin from "@/mixins/notesHelpersMixin";
import formHelpersMixin from "@/mixins/formHelpersMixin";
import {
  addOrRemoveOrUpdateNotesInLocalList,
  checkIfNoteIsEdited,
  createUpdateAndEditedNoteData,
  getUserNotes,
  processNote,
} from "@/helpers/notes";
import { findParentFromPathsByClassName } from "@/utils/hasParentEl";
import { getPathsFromEvent } from "@/utils/events";
import { isGroupRow } from "@/core/components/DataTable/helpers/group";
import cloneDeep from "lodash/cloneDeep";
import { taskTypePlaceholders } from "@/data/taskTypes";
import { decrement, increment } from "@/utils/number";
import { createStrWithMentionsFromQlDelta } from "@/helpers/tasks";
import DatabaseInterface from "@/services/DatabaseInterface";
import viewHelpersMixin from "@/mixins/viewHelpersMixin";
import { emptyDescrTypes } from "@/variables/common";
import isEscKey from "@/utils/isEscKey";
export default {
  mixins: [notesHelpersMixin, formHelpersMixin, viewHelpersMixin],
  components: {
    FormLayout,
    // eslint-disable-next-line vue/no-unused-components
    CreatedAndModifiedViewer,
    TitleEditor,
    ItemTypeMenu,
    DescrEditor,
    NoteFolderSelector,
  },
  data() {
    return {
      newNoteData: {},
      areChangesSaved: false,
      loading: true,
      showEditor: false,
      dataIsLoaded: false,
      triggerEditChecker: false,
      currItemIndex: 0,
      descrData: "",
      descrDelta: [],
      titleDelta: [],
    };
  },
  mounted() {
    this.closeFormAfterAction = true;
    this.debouncedDescrSave = debounce(this.saveNoteDescr, 500);
    this.debouncedConvertAndSetDelta = debounce(this.convertTitleAndSet, 500);
    this.debounedConvertAndSetDescrDelta = debounce(
      this.convertDescrDelta,
      500
    );
    this.init();
  },
  methods: {
    ...mapActions({
      loaderToggler: "toggleLoader",
      updateRootState: "updateRootState",
    }),
    ...mapActions("editForm", ["setNoteData"]),
    handleOpenSelectedItem(noteData) {
      if (!this.isOpen) return;
      const isDataChanged = this.checkIfAnyEditIsMade();
      if (isDataChanged) {
        this.useNoteDataForLoad = true;
        this.noteDataToSet = { ...noteData };
        this.showConfirmBoxForChanges(undefined, false);
        return;
      }
      this.setProvidedNoteDataInForm(noteData);
    },
    handleConvertSuccess() {
      this.closeDialog();
    },
    async saveNoteDescr(desrcData) {
      const currNoteData = this.noteData;
      let updatedNoteData = this.newNoteData;

      const currRawNotes = getUserNotes().getNotes();

      const currRawData = currRawNotes.find((n) => n.key === currNoteData.key);
      if (isEmpty(currRawData) || currRawData.descr === desrcData) return;

      updatedNoteData = {
        descr: desrcData || "",
      };
      const { updates, editedData } = createUpdateAndEditedNoteData(
        updatedNoteData,
        currNoteData
      );
      this.updateMultiNotesInNotesList({
        updates,
        editedData: { ...editedData, refreshDescr: true },
        key: currNoteData.key,
      });

      addOrRemoveOrUpdateNotesInLocalList(
        {
          notesToUpdate: [
            {
              key: currNoteData.key,
              updates,
            },
          ],
        },
        false
      );
      await Vue.nextTick();
      this.areChangesSaved = true;
      this.updateLocalNoteData({
        ...currNoteData,
        ...updates,
      });
    },
    updateLocalNoteData(data) {
      const processedNote = processNote(data);
      this.setNoteData({ ...processedNote });
    },
    handleTitleChange(val) {
      if (this.isNoteDataValid) {
        this.newNoteData.title = val;
      }
      this.debouncedConvertAndSetDelta(val);
    },
    handleDescrDataChange(val) {
      if (this.areChangesSaved) this.areChangesSaved = false;
      this.descrData = val;

      this.debounedConvertAndSetDescrDelta(val);
    },
    handleNoteEditType(type, event) {
      const isDataChanged = this.checkIfAnyEditIsMade();

      if (isDataChanged) {
        const parentRes = findParentFromPathsByClassName(
          getPathsFromEvent(event),
          ["task-action-btn"],
          "obj"
        );

        this.showConfirmBoxForChanges(parentRes.parentEl, false);
        return;
      }
      this.changeItemForEdit(type);
    },
    changeItemForEdit(type) {
      this.loading = true;

      this.getNoteData(type);
    },
    getNoteData(type) {
      let noteFound = true;
      let currIndex = this.currItemIndex;
      if (currIndex !== undefined || currIndex !== null) {
        if (type === "previous") {
          currIndex--;
        }
        if (type === "next") {
          currIndex++;
        }
        let noteData = this.filteredNotes[currIndex];
        if (isEmpty(noteData)) {
          noteFound = false;
        } else if (isGroupRow(noteData)) {
          currIndex =
            type === "next" ? increment(currIndex, 1) : decrement(currIndex, 1);
          if (
            !this.filteredNotes[currIndex] ||
            !this.filteredNotes[currIndex].key
          ) {
            noteFound = false;
          }
        }

        if (!noteFound) {
          this.loading = false;
          this.showToast("No note found!");
          return;
        }

        noteData = this.filteredNotes[currIndex];
        this.setNoteFormData(noteData, false);
      }
    },
    async setNoteFormData(dataToSet, processData = true) {
      const noteObj = processData ? processNote(dataToSet) : dataToSet;
      this.setNoteData({
        ...noteObj,
      });

      await this.$nextTick();

      this.reloadItemData();
    },
    reloadItemData(onlyRefreshData, refreshDescrText) {
      if (this.isNoteDataValid) {
        if (onlyRefreshData) {
          this.refreshNoteFormData(refreshDescrText);
        } else {
          this.loadNoteData();
        }
      }
    },
    async refreshNoteFormData(refreshDescrOnly) {
      const allNotes = getUserNotes().getNotes();
      const currNoteDataKey = this.noteData.key;
      const currRawNoteData = allNotes.find((n) => n.key === currNoteDataKey);
      if (refreshDescrOnly) {
        this.setDescrText(currRawNoteData.descr || "");
      } else {
        const newNoteData = cloneDeep(currRawNoteData);
        const finalNoteData = {
          ...this.newNoteData,
          ...newNoteData,
        };

        const noteDescr = this.addNewLineInText(finalNoteData.decr);
        this.newNoteData = { ...finalNoteData, descr: noteDescr };

        await this.$nextTick();

        const currTitleConvertedData = this.$refs.editor.convertHTMLToDelta(
          this.noteData.title
        );

        const currDescrDelta = this.$refs.editor.convertHTMLToDelta(
          this.noteData.descr
        );

        const convertedRawTitleDelta = this.$refs.editor.convertHTMLToDelta(
          finalNoteData.title
        );

        const convertedRawDescrDelta =
          this.$refs.editor.convertHTMLToDelta(noteDescr);
        this.setDescrText(noteDescr);
        this.$refs.titleInput.setDeltaContents(convertedRawTitleDelta, false);
        this.currTitleDelta = currTitleConvertedData;
        this.currDescrDelta = currDescrDelta;
        this.descrDelta = convertedRawDescrDelta;
        this.titleDelta = convertedRawTitleDelta.ops;
      }
    },
    checkNoteDataForAnyEdits() {
      const currTitleDelta = this.currTitleDelta;
      const updatedTitleDelta = this.titleDelta;
      const updatedDescrDelta = this.descrDelta;
      const currStitchedText = createStrWithMentionsFromQlDelta(currTitleDelta);
      let currSitchedDescrText = createStrWithMentionsFromQlDelta(
        this.currDescrDelta
      );
      const updatedStitchedText = createStrWithMentionsFromQlDelta({
        ops: updatedTitleDelta,
      });
      let updatedSitchedDescrText =
        createStrWithMentionsFromQlDelta(updatedDescrDelta);

      let isDataChanged = checkIfNoteIsEdited(
        {
          ...this.newNoteData,
          descr: updatedSitchedDescrText,
          title: updatedStitchedText,
        },
        {
          ...this.noteData,
          descr: currSitchedDescrText,
          title: currStitchedText,
          itemType: "note",
        }
      );

      return isDataChanged;
    },
    checkIfAnyEditIsMade() {
      return this.checkNoteDataForAnyEdits();
    },
    async checkForEditAndCloseForm() {
      const taskDataChanged = this.checkIfAnyEditIsMade();
      if (taskDataChanged) {
        await this.showConfirmBoxForChanges(undefined, undefined, true);
        return;
      } else {
        this.closeFormAfterAction = true;
      }

      this.closeDialog();
    },

    returnToOriginalView(viewData, doNotRun) {
      // let dbUpdates = {};
      // let viewConfig = {};
      // let routeName = "";
      if (
        !this.isOpen ||
        (this.userActionType && this.userActionType !== "close-form") ||
        doNotRun ||
        isEmpty(viewData)
      )
        return;

      this.loaderToggler(true);

      this.handleNotesViewConfig(viewData);
      // if (
      //   (!this.userActionType || this.userActionType === "close-form") &&
      //   !doNotRun &&
      //   !isEmpty(returnViewData)
      // ) {

      // if (viewData.to !== undefined) {
      //   const { to, ...restConfig } = viewData;
      //   routeName = to;
      //   viewConfig = { ...restConfig };
      //   if (to === "Dashboard") {
      //     dbUpdates = {
      //       notesView: false,
      //       activeNavView: "dashboard",
      //     };
      //   } else if (to === "Resources") {
      //     dbUpdates = {
      //       notesView: false,
      //       activeNavView: "resources",
      //     };
      //   }
      //   // }

      //   if (!isEmpty(dbUpdates)) {
      //     DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      //   }

      //   this.$router.replace({
      //     name: routeName,
      //     params: {
      //       viewConfig: {
      //         ...viewConfig,
      //       },
      //     },
      //   });
      // } else {
      //   this.handleNotesViewConfig(viewData);
      //   //
      // }
    },
    closeDialog(ignoreOtherSteps) {
      const originalViewData =
        this.closedByEsc && !isEmpty(this.storedViewData)
          ? this.storedViewData
          : {};

      if (this.closeFormAfterAction) {
        this.returnToOriginalView(
          cloneDeep(originalViewData),
          ignoreOtherSteps
        );

        if (!this.closedByEsc) {
          this.updateRootState({
            currViewData: {},
            itemInspectEnabled: false,
          });
        }

        this.closeEditTaskDialog();
      }

      this.closedByEsc = false;
    },
    closeForm() {
      this.checkForEditAndCloseForm();
    },
    checkIfCheckFlowShouldContinue(eventData) {
      if (!isEmpty(eventData)) {
        const isEscKeyPress = isEscKey(eventData);
        const parentRes = findParentFromPathsByClassName(
          getPathsFromEvent(eventData),
          ["form-grid", "editable-area", "item-selector-menu"],
          "obj"
        );
        if (isEscKeyPress && parentRes.found) {
          return false;
        }

        return true;
      }
      return true;
    },
    setRef(ref, name) {
      this.$refs[name] = ref;
    },
    changeItemType(val) {
      this.newNoteData.itemType = val;
    },
    submitData() {
      this.processNoteData();
    },
    getNoteDescriptionVal() {
      return this.getDescrVal();
    },
    async processNoteData() {
      const currNoteData = this.noteData;
      let updatedNoteData = this.newNoteData;

      updatedNoteData = {
        ...this.newNoteData,
        descr: this.getNoteDescriptionVal() || "",
      };
      if (updatedNoteData.itemType && updatedNoteData.itemType === "task") {
        this.setDataForConvertMode({
          dataList: [
            {
              ...updatedNoteData,
            },
          ],
          typeToUse: "task",
          calledFrom: "edit-form",
        });

        return;
      }
      this.loading = true;

      const { updates, editedData } = createUpdateAndEditedNoteData(
        updatedNoteData,
        currNoteData
      );
      this.updateMultiNotesInNotesList({
        updates,
        editedData,
        key: currNoteData.key,
      });

      addOrRemoveOrUpdateNotesInLocalList({
        notesToUpdate: [
          {
            key: currNoteData.key,
            updates,
          },
        ],
      });

      await Vue.nextTick();

      this.setNoteFormData({ ...this.newNoteData, ...updates });
      this.callActionAfterSubmitSuccess();
    },
    callActionAfterSubmitSuccess() {
      const formActions = ["item-change", "close-form"];
      if (this.resumeActionAfterSubmit && this.userActionType) {
        if (formActions.includes(this.userActionType)) {
          if (this.userActionType === formActions[0]) {
            this.changeItemForEdit(this.userActionValue);
          }

          if (this.userActionType === formActions[1]) {
            this.closeDialog();
          }

          this.resetUserAction();
        } else {
          this.triggerActionActionAfterSubmit();
        }
      } else if (this.useNoteDataForLoad && !isEmpty(this.noteDataToSet)) {
        this.setProvidedNoteDataInForm({ ...this.noteDataToSet });
      }
    },
    setProvidedNoteDataInForm(noteData) {
      this.useNoteDataForLoad = false;
      this.noteDataToSet = {};
      this.setNoteFormData(noteData);
    },
    resetNoteData() {
      this.newNoteData = {};
    },

    async loadNoteData() {
      this.loading = true;
      this.dataIsLoaded = false;
      try {
        const allNotes = getUserNotes().getNotes();
        const currNoteDataKey = this.noteData.key;
        const currRawNoteData = allNotes.find((n) => n.key === currNoteDataKey);

        const noteDescr = this.addNewLineInText(currRawNoteData.descr);

        this.newNoteData = {
          ...cloneDeep(currRawNoteData),
          itemType: "note",
          descr: noteDescr,
        };
        this.currItemIndex = this.filteredNotes.findIndex(
          (n) => n.key === currNoteDataKey
        );
        await this.$nextTick();

        const currTitleConvertedData = this.$refs.editor.convertHTMLToDelta(
          this.noteData.title
        );

        const currNoteDescrDelta = this.$refs.editor.convertHTMLToDelta(
          this.noteData.descr
        );
        const convertedDelta = this.$refs.editor.convertHTMLToDelta(noteDescr);
        const convertedRawTitleDelta = this.$refs.editor.convertHTMLToDelta(
          currRawNoteData.title
        );

        this.$refs.editor.setDeltaContents(convertedDelta, false);
        this.$refs.titleInput.setDeltaContents(convertedRawTitleDelta, false);
        this.currTitleDelta = currTitleConvertedData;
        this.currDescrDelta = currNoteDescrDelta;
        this.descrDelta = convertedDelta;
        this.titleDelta = convertedRawTitleDelta.ops;
        setTimeout(() => {
          this.focusOnTitleInput(true);
        }, 0);
        this.dataIsLoaded = true;

        this.loaderToggler(false);
      } catch (error) {
        this.loaderToggler(false);
        console.error("Error in loadNoteData", error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("editForm", {
      isOpen: "isOpen",
      noteData: "noteData",
    }),
    ...mapGetters({
      storedViewData: "currViewData",
    }),
    ...mapGetters("note", {
      filteredNotes: "filteredNotes",
      totalNotesCount: "notesCount",
    }),
    createdAndModifiedValues() {
      const createdProp = "created";
      const modifiedProp = "modified";

      const dataToUse = this.noteData;
      return {
        created: dataToUse[createdProp],
        modified: dataToUse[modifiedProp],
      };
    },
    createdAndModifiedDates() {
      const { created, modified } = this.createdAndModifiedValues;
      return {
        modifiedDate: convertDateTimeToReadableFormat(
          modified,
          DATE_WITH_TIME_FORMAT_IN_USE,
          "N/A"
        ),
        createdDate: convertDateTimeToReadableFormat(
          created,
          DATE_WITH_TIME_FORMAT_IN_USE,
          "N/A"
        ),
      };
    },
    isNoteDataValid() {
      return (
        this.isOpen &&
        !isEmpty(this.noteData) &&
        !isEmpty(this.newNoteData) &&
        !isEmpty(this.newNoteData.key)
      );
    },
    totalItemCount() {
      return this.totalNotesCount;
    },
    editorPlaceholder() {
      const type = this.newNoteData.itemType === "task" ? "task" : "note";

      return taskTypePlaceholders[type];
    },
    isEditStarted() {
      let isDataChanged = false;
      const checkForEdit = this.dataIsLoaded || this.triggerEditChecker;
      const isOpen = this.isOpen;

      if (isOpen && checkForEdit && this.isNoteDataValid) {
        isDataChanged = this.checkIfAnyEditIsMade();
      }

      return isDataChanged;
    },
  },
  watch: {
    isOpen: {
      handler(v) {
        if (v && !isEmpty(this.noteData)) {
          this.loadNoteData();
        } else {
          this.resetNoteData();
        }
      },
      // immediate: true,
    },
  },
};
</script>
<style>
.note-title-input-wrapper #title-editor {
  border: none;
}
.note-title-input-wrapper #title-editor .ql-editor {
  min-height: 34px !important;
  padding: 6px 0px 4px 0px !important;
}

.descr-editor-wrapper .ql-editor {
  background-color: var(--editor-color);
}
/* .descr-editor-wrapper .ql-editor.ql-blank::before {
  top: 10px;
  font-style: normal !important;
  color: #a6a6a6 !important;
} */

.note-title-input-wrapper #title-editor .ql-editor.ql-blank::before {
  top: 8px;
  left: 0px;
  font-style: normal !important;
  color: #a6a6a6 !important;
}

.descr-editor .ql-container {
  font-size: 16px;
}
/* .descr-editor-wrapper .ql-editor {
  min-height: 250px !important;
  resize: vertical;
  overflow-y: auto;
  color: rgba(51, 51, 51);
} */

/* .descr-editor-wrapper .ql-editor p {
  margin: 0px 0px 0px 0px;

} */

.note-title-input-wrapper >>> #title-edit:before {
  margin-bottom: 5px !important;
}
</style>
