<template>
  <div>{{ name }}</div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: ["data", "options"],
  computed: {
    ...mapGetters("note", ["directories"]),
    name() {
      const path = this.directories[this.data.path];
      const name = path?.name || "No Topic";
      return name;
    },
  },
};
</script>
