import {
  createDirectoryTree,
  createRestoredDataOfDir,
} from "@/helpers/directories";
import userDetailsMixin from "./userDetailsMixin";
import { DIRECTORY_ACTIONS } from "@/helpers/actionHistory";
import notesHelpersMixin from "./notesHelpersMixin";
import { mapGetters, mapActions } from "vuex";
import { cloneDeep, isEmpty } from "lodash";
import createUniqueId from "@/utils/createUniqueId";
import { getCurrDate } from "@/helpers/dates";
import { getUserNotes } from "@/helpers/notes";
import categoriesHelpersMixin from "@/mixins/categoriesHelpersMixin";
import { convertValueToArray } from "@/helpers/common";

import EventEmitter from "@/helpers/eventEmitter";
import { REFRESH_TREE_EVENT } from "@/variables/events";
import { increment } from "@/utils/number";
const mixin = {
  mixins: [userDetailsMixin, notesHelpersMixin, categoriesHelpersMixin],
  methods: {
    async handleUndoOnDirectory(action) {
      switch (action.type) {
        case DIRECTORY_ACTIONS.ADD:
        case DIRECTORY_ACTIONS.BATCH_ADD:
          this.handleRemoveDirectory(action.data);
          break;
        case DIRECTORY_ACTIONS.DELETE:
          this.handleAddDirectory(action.data);
          break;
        case DIRECTORY_ACTIONS.EDIT:
        case DIRECTORY_ACTIONS.BATCH_EDIT:
        case DIRECTORY_ACTIONS.REORDER_OR_MOVE:
          this.restoreDirsData(action.data);
          break;

        default:
        // Do nothing
      }
    },
    async handleRedoOnDirectory(action) {
      switch (action.type) {
        case DIRECTORY_ACTIONS.ADD:
          await this.handleAddDirectory(action.data);
          break;
        case DIRECTORY_ACTIONS.DELETE:
          await this.handleRemoveDirectory(action.data);
          break;
        case DIRECTORY_ACTIONS.EDIT:
        case DIRECTORY_ACTIONS.BATCH_EDIT:
        case DIRECTORY_ACTIONS.REORDER_OR_MOVE:
          await this.restoreDirsData(action.data, true);
          break;
        default:
        // Do nothing
      }
    },
    async addDirWithOrder(dirToAdd) {
      let parentKey = dirToAdd.parentKey || "";
      let dirOrder = dirToAdd.order || 0;

      let currDirectories = cloneDeep(this.directories);
      const createdDirData = {
        name: dirToAdd.name || "New Folder",
        order: dirOrder,
        open: typeof dirToAdd.open === "boolean" ? dirToAdd.open : false,
        key: dirToAdd.key || createUniqueId(),
        created: dirToAdd.created || getCurrDate(),
        modified: dirToAdd.modified || getCurrDate(),
        parentKey,
      };

      currDirectories = {
        ...currDirectories,
        [createdDirData.key]: createdDirData,
      };

      let children = this.notesTree;
      if (parentKey && this.directories[parentKey]) {
        children = this.dirChildrenMap[parentKey] || [];
      }

      children = cloneDeep(children);
      if (children) {
        if (children.length) {
          children.splice(dirOrder, 0, createdDirData);
          children.sort((a, b) => a.order - b.order);
          children.forEach((c, index) => {
            if (currDirectories[c.key]) {
              currDirectories[c.key] = {
                ...currDirectories[c.key],
                order: index,
                parentKey: currDirectories[c.key].parentKey || "",
              };
            }
          });
        } else {
          currDirectories[createdDirData.key] = {
            ...currDirectories[createdDirData.key],
            order: 0,
            parentKey: currDirectories[createdDirData.key].parentKey || "",
          };
        }
      }

      await getUserNotes().set(currDirectories, "directories");
    },
    async restoreDirsData(dirsToRestore, isRedoAction) {
      if (!Array.isArray(dirsToRestore)) dirsToRestore = [dirsToRestore];
      const parentChangedList = [];
      const renameList = [];

      dirsToRestore.forEach((dir) => {
        const { updates, type } = createRestoredDataOfDir(
          isRedoAction ? dir.changedProps : dir
        );

        if (type) {
          if (type === "parent-changed") {
            parentChangedList.push({
              ...updates,
              key: dir.key,
            });
          } else if (type === "rename") {
            renameList.push({
              ...updates,
              key: dir.key,
            });
          }
        }
      });

      this.renameDirs(renameList);
      this.changeParents(parentChangedList);
      // await this.updateMultiDir(dirsToRestore, false);
    },
    async renameDirs(dirsToRename) {
      if (dirsToRename && dirsToRename.length) {
        const dirUpdates = dirsToRename.map((d) => ({
          key: d.key,
          updates: { title: d.name },
        }));

        this.updateNameOfCategoryInTree(dirUpdates, "#directory-tree", true);
      }
    },
    async changeParents(dirsParentChange) {
      if (dirsParentChange && dirsParentChange.length) {
        const rootNodes = [];
        dirsParentChange.forEach((d) => {
          const itemUpdates = d;
          const dirOrder = itemUpdates.order;

          const parentKey = itemUpdates.parentKey;

          const currData = this.directories[d.key];

          if (!isEmpty(currData)) {
            const itemData = {
              ...currData,
              order: dirOrder,
              key: d.key,
            };

            if (parentKey !== undefined && parentKey !== currData.parentKey) {
              itemData.parentKey = parentKey;
            }
            if (itemData.parentKey) {
              this.moveNodesInTree(
                itemData,
                itemData.parentKey,
                "#directory-tree"
              );
            } else {
              rootNodes.push({ key: d.key, updates: { ...itemData } });
            }
          }
        });
        if (!isEmpty(rootNodes)) {
          await this.updateMultiDir(rootNodes, false);
          EventEmitter.emit(REFRESH_TREE_EVENT);
        }
      }
    },

    updateDirsInDbAndTree(dirUpdatesMap) {
      const currDirectories = cloneDeep(this.directories);
      const treeComponent =
        document.querySelector("#directory-tree").ej2_instances[0];
      const dirUpdatesKeys = Object.keys(dirUpdatesMap);
      for (const dirKey of dirUpdatesKeys) {
        dirUpdatesMap[dirKey].forEach((dir) => {
          currDirectories[dir.key] = {
            ...currDirectories[dir.key],
            ...dir.updates,
          };
        });
      }

      const { dirChildrenMap, ...fieldsToSet } = createDirectoryTree({
        directories: currDirectories,
        notes: this.notes,
        selectedNodes: this.selectedFolders,
      });

      this.updateNoteState({
        notesTreeStructure: fieldsToSet.dataSource,
        dirChildrenMap,
      });
      treeComponent.fields.dataSource = fieldsToSet.dataSource;

      getUserNotes().set(currDirectories, "directories");
    },
    handleRemoveDirectory(data) {
      const dirList = convertValueToArray(data);
      const dirIdsToRemove = dirList.map((d) => d.key);
      this.removeCategoryFromTree(dirIdsToRemove, "#directory-tree");
    },
    handleAddDirectory(data) {
      const dirDataWithAttrs = this.addAttributesInCate(data, "notes-tree");
      const parentKey = data.parentKey ? data.parentKey : "";

      if (!parentKey) {
        dirDataWithAttrs.order = increment(dirDataWithAttrs.order, 1);
      }
      this.addCategoryInTree(
        dirDataWithAttrs,
        parentKey,
        undefined,
        "#directory-tree"
      );
    },

    ...mapActions("note", ["updateNoteState"]),
  },
  computed: {
    ...mapGetters("note", [
      "directories",
      "notesTree",
      "notes",
      "dirChildrenMap",
      "selectedFolders",
    ]),
  },
};

export default mixin;
