<template>
  <div class="note-type-selector-wrapper" @click.stop @keydown.stop>
    <ItemTypeMenu
      :value="data.itemType"
      @input="onItemTypeChange"
      ref="select"
      type="note"
      :iconColor="greyIconColor"
    />
  </div>
</template>
<script>
import { columnPropsMixin } from "@/core/components/DataTable";
import ItemTypeMenu from "@/components/ItemTypeMenu";
import { mapActions } from "vuex";
import {
  // DARK_GREY_ICON_COLOR,
  NEW_DARK_ICON_COLOR,
} from "@/variables/colors";
export default {
  mixins: [columnPropsMixin],
  components: {
    ItemTypeMenu,
  },
  methods: {
    ...mapActions("convertModeData", ["setDataForConvertMode"]),
    onItemTypeChange(val) {
      if (val === "task") {
        this.setDataForConvertMode({
          dataList: [{ ...this.data }],
          typeToUse: "task",
        });
        return;
      }
    },
  },
  computed: {
    greyIconColor() {
      return NEW_DARK_ICON_COLOR;
    },
  },
};
</script>
<style scoped>
.note-type-selector-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.note-type-selector-wrapper >>> .v-select {
  margin-top: 0px !important;
}

.note-type-selector-wrapper >>> #activator-btn-wrapper {
  height: 34px;
}
.note-type-selector-wrapper >>> #activator-btn-wrapper button {
  height: 34px !important;
  width: 34px !important;
}

.note-type-selector-wrapper >>> .v-select__slot input[readonly="readonly"] {
  height: 100%;
}
</style>
