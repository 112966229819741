export const DIRECTORY_ACTIONS_TYPES = {
  ADD_DIR: "add_dir",
  ADD_SUB_DIR: "add_sub_dir",
  ADD_NOTE: "add_note",
  REMOVE_NOTE_CATE: "remove-note-cate",
  RENAME_NOTE_CATE: "rename-note-cate",
};

export const DIRECTORY_ACTIONS = [
  { text: "Add New Topic", id: DIRECTORY_ACTIONS_TYPES.ADD_DIR },
  { text: "Add New Sub Topic", id: DIRECTORY_ACTIONS_TYPES.ADD_SUB_DIR },
  { text: "Add Note", id: DIRECTORY_ACTIONS_TYPES.ADD_NOTE },
  { text: "Rename Item", id: DIRECTORY_ACTIONS_TYPES.RENAME_NOTE_CATE },
  { text: "Remove Item", id: DIRECTORY_ACTIONS_TYPES.REMOVE_NOTE_CATE },
];
