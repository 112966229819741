<template>
  <div id="directory-tree-side-bar">
    <div
      class="pl-3 pb-5"
      id="directory-tree-wrapper"
      @drop="handleNoteDrop"
      @dragover.prevent
      @mousedown="handleMouseDown"
      @click="handleClick"
      @dblclick="handleNodeDbClick"
    >
      <TreeView
        :refSetter="storeTreeRef"
        id="directory-tree"
        :fields="fields"
        cssClass="directoryTree"
        :nodeTemplate="Template"
        :allowDragAndDrop="true"
        :allowEditing="allowEditing"
        dragArea="#directory-tree-wrapper"
        :enablePersistence="true"
        :expandOn="'None'"
        :allowMultiSelection="false"
        :selectedNodes="selectedNodes"
        @keyPress="handleKeyDown"
        @nodeClicked="nodeclicked"
        @nodeEdited="nodeEdited"
        @nodeSelected="nodeSelected"
        @nodeCollapsed="nodeCollapsed"
        @nodeExpanded="nodeExpanded"
        @dataSourceChanged="treeEdited"
        @nodeDragStart="dragStart"
        @nodeDragStop="dragStop"
        @nodeDragging="nodeDrag"
      />
    </div>

    <ContextMenu
      parentWrapperId="directory-tree-wrapper"
      target="#directory-tree-wrapper"
      :items="menuItems"
      mainTarget="#directory-tree"
      menuId="directory-menu"
      @beforeMenuOpen="handleBeforeMenuOpen"
      @menu-item:selected="handleMenuItemSelection"
    />
  </div>
</template>
<script>
import DirectoryNode from "./components/NoteCateNode.vue";
import TreeView from "@/components/TreeView";
import ContextMenu from "@/core/components/ContextMenu";
import { DIRECTORY_ACTIONS, DIRECTORY_ACTIONS_TYPES } from "./contextOpts";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import notesHelpersMixin from "@/mixins/notesHelpersMixin";
import {
  createDirectoryDataForAdd,
  createDirectoryTree,
  findDir,
  isChildOrGrandChild,
} from "@/helpers/directories";
import { createDirUpdateAndEditedData } from "@/helpers/directories";
import { mapGetters } from "vuex";
import isEqual from "lodash/isEqual";
import EventEmitter from "@/helpers/eventEmitter";
import hasParentEl, {
  findParentFromPathsByClassName,
} from "@/utils/hasParentEl";
import { mapActions, mapMutations } from "vuex";
import {
  addOrRemoveOrUpdateNotesInLocalList,
  createUpdateAndEditedNoteDataByCell,
  getUserNotes,
} from "@/helpers/notes";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import {
  ADD_NOTE_EVENT,
  CONTEXT_MENU_OPENED,
  REFRESH_DATA_LIST_EVENT,
  REFRESH_TREE_EVENT,
  SET_SELECTED_NODE_EVENT,
  TREE_NODE_MOVED_EVENT,
  TREE_NODE_RENAME_EVENT,
} from "@/variables/events";
import { getPathsFromEvent } from "@/utils/events";
import { PRIMARY_COLOR } from "@/variables/colors";
import { clearAllStoredChildren } from "@/helpers/nodeTreeHelpers";
import { convertValueToArray, findNodeInTree } from "@/helpers/common";
import treeViewHelpersMixin from "@/mixins/treeViewHelpersMixin";
import categoriesHelpersMixin from "@/mixins/categoriesHelpersMixin";
import {
  storeCatAddAction,
  storeCatEditAction,
  storeCatRemoveAction,
  storeCatReorderOrMoveAction,
} from "@/helpers/categories";
import { increment } from "@/utils/number";
import { NODE_BROWSE_KEYS, NODE_MOVE_KEYS } from "@/variables/keys";

export default {
  mixins: [
    userDetailsMixin,
    notesHelpersMixin,
    treeViewHelpersMixin,
    categoriesHelpersMixin,
  ],
  components: {
    ContextMenu,
    TreeView,
  },
  data() {
    return {
      notesMap: {},
      selectedNodes: [],
      Template() {
        return {
          template: DirectoryNode,
        };
      },
      menuItems: DIRECTORY_ACTIONS,
      fields: [],
      contextType: "",
      allowEditing: false,
    };
  },

  methods: {
    ...mapMutations("note", ["setNotesTree"]),

    handleNodeDbClick(e) {
      const paths = getPathsFromEvent(e);

      const { found: nodeItemElFound, parentEl: nodeItemEl } =
        findParentFromPathsByClassName(paths, ["e-list-item"], "obj");

      if (nodeItemElFound) {
        if (this.selectNodeFuncId !== undefined) {
          clearTimeout(this.selectNodeFuncId);
          this.selectNodeFuncId = undefined;
        }
      }

      if (hasParentEl(e.target, { className: ["edit-item-btn", "e-icons"] })) {
        return;
      }

      if (nodeItemEl) {
        const targetId = nodeItemEl.getAttribute("data-uid");

        if (targetId !== "root") {
          this.renameNoteDir(targetId, true);
        }
      }
    },
    async handleKeyDown(args) {
      args.event.stopPropagation();
      await this.$nextTick();
      const { event } = args;
      const keyCode = event.which ? event.which : event.keyCode;
      if (
        NODE_BROWSE_KEYS.includes(keyCode) ||
        NODE_MOVE_KEYS.includes(keyCode)
      ) {
        this.storeNodeActivity = true;
      }

      if (NODE_MOVE_KEYS.includes(keyCode)) {
        const treeView = this.$refs.treeview;
        const focusedNode = this.$el.querySelector(
          ".e-list-item.e-node-focus.e-hover"
        );

        if (focusedNode) {
          let expandNode = false;
          if (focusedNode.classList.contains("e-has-child")) {
            expandNode = true;
          }
          const nodeId = this.getNodeIdFromNodeEl(focusedNode);

          if (nodeId) {
            await this.handleNodesData([nodeId]);
            treeView.ensureVisible(nodeId);
            await this.$nextTick();
            if (expandNode) {
              treeView.expandAll([nodeId]);
            }
          }
        }
      }
    },
    handleClick(e) {
      const targetEl = e.target;
      if (targetEl && targetEl.matches(".e-icons.interaction")) {
        this.storeNodeActivity = true;
      }
    },
    storeTreeRef(refEl) {
      this.$refs["treeview"] = refEl;
    },
    handleMouseDown(e) {
      if (e.button !== 2) return;

      const paths = getPathsFromEvent(e);
      const nodeId = this.getNodeIdFromEventPaths(paths);
      if (nodeId) {
        this.selectedNodeIdToUse = nodeId;
      }
    },
    getNodeIdFromEventPaths(paths) {
      let nodeId;
      for (const path of paths) {
        nodeId = this.getNodeIdFromNodeEl(path);
        if (nodeId) {
          break;
        }
      }
      return nodeId;
    },
    getNodeIdFromNodeEl(nodeEl) {
      let nodeId;

      if (nodeEl && nodeEl.getAttribute && nodeEl.getAttribute("data-uid")) {
        nodeId = nodeEl.getAttribute("data-uid");
      }

      return nodeId;
    },
    handleBeforeMenuOpen(args) {
      const menuEl = document.querySelector("#directory-menu").ej2_instances[0];
      let rootNode = false;
      const selectedNode = this.selectedNodeIdToUse;
      if (!args.openedFromRoot && selectedNode === "root") {
        rootNode = true;
      }

      if (rootNode) {
        menuEl.hideItems(["Rename Item", "Remove Item"]);
      } else {
        menuEl.showItems(["Rename Item", "Remove Item"]);
      }
      this.openedFromRoot = args.openedFromRoot;
    },
    async handleMenuItemSelection(args) {
      await this.$nextTick();
      const selectedMenuItem = args.item;
      const nodeToUse = this.selectedNodeIdToUse;
      switch (selectedMenuItem.id) {
        case DIRECTORY_ACTIONS_TYPES.ADD_DIR:
          this.addFolder(nodeToUse);
          break;
        case DIRECTORY_ACTIONS_TYPES.ADD_SUB_DIR:
          this.addSubFolder(nodeToUse);
          break;
        case DIRECTORY_ACTIONS_TYPES.ADD_NOTE:
          this.addNoteInDir(nodeToUse);
          break;
        case DIRECTORY_ACTIONS_TYPES.RENAME_NOTE_CATE:
          this.renameNoteDir(nodeToUse);
          break;
        case DIRECTORY_ACTIONS_TYPES.REMOVE_NOTE_CATE:
          this.deleteNoteDir(nodeToUse);
          break;
      }
    },
    renameNoteDir(targetNodeId, doNotSelect) {
      if (!targetNodeId) return;

      this.renameEnabledByUser = true;

      if (!doNotSelect) {
        this.handleNodesData([targetNodeId]);
      }
      this.renameCateItemInTree(targetNodeId, "#directory-tree");
    },
    deleteNoteDir(targetNodeId) {
      if (!targetNodeId) return;
      // const currDirectories = this.directories;
      const currNotes = this.notes;
      const target = "#directory-tree";
      const storedDir = this.directories[targetNodeId];

      if (!isEmpty(storedDir)) {
        const childFoldersExists = Object.values(this.directories).some(
          (d) => d.parentKey === targetNodeId
        );
        let notesExists = false;
        if (!childFoldersExists) {
          notesExists = currNotes.some((n) => n.path === targetNodeId);
        }

        const itemsExists = childFoldersExists || notesExists;

        if (itemsExists) {
          this.$swal({
            titleText: "Oops!",
            html: "Cannot delete because it contains items.<br/>Please delete them first",
            icon: "error",
            confirmButtonColor: PRIMARY_COLOR,
            iconColor: PRIMARY_COLOR,
          });

          // this.$swal(
          //   "Oops!",
          //   "Cannot delete because it contains items.<br/>Please delete them first",
          //   "error"
          // );
          return;
        }

        const folderData = this.directories[storedDir.key];

        if (!isEmpty(folderData)) {
          this.removeCategoryFromTree(folderData.key, target);
          storeCatRemoveAction({ ...folderData }, "subjects");
        }

        // await this.removeFolderFromDirs(storedDir);
        // this.resortChildrenInParentFolder(storedDir.key);
      }
    },
    setContextMenuNodeId(nodeId) {
      this.selectedNodeIdToUse = nodeId;
    },
    addNoteInDir(targetNodeId) {
      let path = "";
      if (!this.openedFromRoot) {
        path = targetNodeId && targetNodeId !== "root" ? targetNodeId : "";
      }
      this.addNote({ path });
    },
    addNote(data = {}) {
      EventEmitter.emit(ADD_NOTE_EVENT, data);
    },

    addFolder(targetNodeId) {
      // let target = this.mainTarget ? this.mainTarget : this.target;

      const target = "#directory-tree";
      const currFolders = this.notesTree;
      const currDirChildMap = this.dirChildrenMap;
      const allDirs = this.directories;
      let selectedNode = "root";
      let dirName = "New Topic";
      if (!this.openedFromRoot) {
        selectedNode = targetNodeId;
      }

      if (selectedNode) {
        let catIdToSet = "";
        let orderIndex = 0;
        let extraData = {};
        if (selectedNode !== "root" && this.directories[selectedNode]) {
          catIdToSet = this.directories[selectedNode].parentKey || "";
          orderIndex = this.directories[selectedNode].order;
          extraData = {
            order: orderIndex + 1,
            parentKey: catIdToSet,
          };
        } else {
          extraData = {
            order: 0,
            parentKey: "",
          };
        }

        const dir = createDirectoryDataForAdd(
          {
            name: dirName,
            parentKey: catIdToSet,
          },
          currDirChildMap,
          currFolders,
          allDirs,
          extraData
        );

        const cateDataToSet = { ...dir };

        if (!catIdToSet) {
          cateDataToSet.order = increment(cateDataToSet.order, 1);
        }

        this.setSelectedNodesInTree([cateDataToSet.key]);
        const cateWithAttrs = this.addAttributesInCate(
          cateDataToSet,
          "notes-tree"
        );
        this.addCategoryInTree(
          {
            ...cateWithAttrs,
            children: [],
          },
          catIdToSet,
          undefined,
          target,
          true
        );

        this.setCateInSelectedOpts([cateWithAttrs.key], "subjects");
        storeCatAddAction(dir, "notes-subject");
      }
    },
    addSubFolder(targetNodeId) {
      // let target = this.mainTarget ? this.mainTarget : this.target;

      const target = "#directory-tree";
      const currFolders = this.notesTree;
      const currDirChildMap = this.dirChildrenMap;
      const allDirs = this.directories;
      let selectedNode = "root";
      let dirName = "New Sub Topic";
      if (!this.openedFromRoot) {
        selectedNode = targetNodeId;
      }

      if (selectedNode) {
        let catIdToSet = "";
        let extraData = {};
        // let orderIndex = 0;
        if (selectedNode !== "root" && this.directories[selectedNode]) {
          catIdToSet = this.directories[selectedNode].key;
          // orderIndex = this.directories[selectedNode].order;
          // extraData = {
          //   order: orderIndex + 1,
          //   parentKey: catIdToSet,
          // };
        } else {
          extraData = {
            order: 0,
            parentKey: "",
          };
        }

        const dir = createDirectoryDataForAdd(
          {
            name: dirName,
            parentKey: catIdToSet,
          },
          currDirChildMap,
          currFolders,
          allDirs,
          extraData
        );

        const cateDataToSet = { ...dir };

        if (!catIdToSet) {
          cateDataToSet.order = increment(cateDataToSet.order, 1);
        }

        this.setSelectedNodesInTree([cateDataToSet.key]);
        const cateWithAttrs = this.addAttributesInCate(
          cateDataToSet,
          "notes-tree"
        );
        this.addCategoryInTree(
          {
            ...cateWithAttrs,
            children: [],
          },
          catIdToSet,
          undefined,
          target,
          true
        );

        this.setCateInSelectedOpts([cateWithAttrs.key], "subjects");
        storeCatAddAction(dir, "notes-subject");
      }
    },
    dragStart() {
      if (!this.dragStarted) {
        this.dragStarted = true;
      }
    },
    enableNodeRename(enableEdit, disableAfterEdit) {
      this.allowEditing = enableEdit;

      this.disableAfterUpdate = disableAfterEdit;
      // this.renameEnabledByUser = true;
    },
    refreshAfterNodeReordered() {
      this.nodeReordered = true;
    },
    setListeners() {
      EventEmitter.on(TREE_NODE_RENAME_EVENT, this.enableNodeRename);
      EventEmitter.on(TREE_NODE_MOVED_EVENT, this.refreshAfterNodeReordered);
      EventEmitter.on(REFRESH_TREE_EVENT, this.refreshTreeAfterEdit);
      EventEmitter.on(SET_SELECTED_NODE_EVENT, this.setSelectedNodesInTree);
      EventEmitter.on(CONTEXT_MENU_OPENED, this.setContextMenuNodeId);
    },
    removeListeners() {
      EventEmitter.off(TREE_NODE_RENAME_EVENT, this.enableNodeRename);
      EventEmitter.off(TREE_NODE_MOVED_EVENT, this.refreshAfterNodeReordered);
      EventEmitter.off(REFRESH_TREE_EVENT, this.refreshTreeAfterEdit);
      EventEmitter.off(SET_SELECTED_NODE_EVENT, this.setSelectedNodesInTree);
      EventEmitter.off(CONTEXT_MENU_OPENED, this.setContextMenuNodeId);
    },
    init() {
      this.setListeners();
      this.setSelectedFolders();
      this.createAndSetDirectoryTree();
    },
    setSelectedNodesInTree(selectedNodes) {
      selectedNodes = convertValueToArray(selectedNodes);
      this.selectedNodes = selectedNodes;
    },
    setSelectedFolders() {
      // if (this.selectedFolders && this.selectedFolders.length) {
      this.setSelectedNodesInTree(this.selectedFolders);
      // this.selectedNodes = this.selectedFolders;
      // }
    },
    setNodeAndGetData(selectedIds) {
      selectedIds = convertValueToArray(selectedIds);

      this.handleNodesData(selectedIds);
    },
    async refreshTreeAfterEdit() {
      await this.$nextTick();
      this.createAndSetDirectoryTree();
    },
    async createAndSetDirectoryTree(
      directoriesToUse,
      clearNodeChildren = true,
      updateFields = true
    ) {
      const treeView = this.$refs.treeview;
      const directories = !isEmpty(directoriesToUse)
        ? directoriesToUse
        : this.directories;
      const { dirChildrenMap, ...fields } = createDirectoryTree({
        directories,
        notes: this.rawNotes,
        selectedNodes: this.selectedNodes,
      });
      const clonedDataSource = cloneDeep(fields.dataSource);
      this.setNotesTreeStructure({
        tree: clonedDataSource.filter((ch) => ch.key !== "root"),
        dirChildrenMap,
      });

      if (clearNodeChildren) {
        clearAllStoredChildren();
      }

      if (updateFields) {
        this.fields = fields;
      }

      await this.$nextTick();

      treeView.ej2Instances.dataType = 2;

      treeView.ensureVisible(this.selectedNodes[0]);
    },
    flattenUpdatedTreeData(treeData) {
      let updatedCateData = {};
      const treeview = this.$refs.treeview;
      //get all category data
      for (let i = 0; i < treeData.length; i++) {
        const nodeData = treeview.getTreeData(treeData[i].key)[0];
        updatedCateData[treeData[i].key] = {
          order: i,
          name: treeData[i].name,
          key: treeData[i].key,
          open: nodeData?.open || false,
          modified: treeData[i].modified,
          created: treeData[i].created,
          parentKey: "",
        };
        //if top level node has children
        if (treeData[i].children && treeData[i].children.length > 0) {
          for (let j = 0; j < treeData[i].children.length; j++) {
            //get children keys
            const nestedUpdatedChildrenData = this.getNestedUpdatedChildrenData(
              treeData[i].children,
              treeData[i].key
            );

            updatedCateData = Object.assign(
              updatedCateData,
              nestedUpdatedChildrenData
            );
          }
        }
      }
      return updatedCateData;
    },
    getNestedUpdatedChildrenData(childTree, parentCatKey) {
      const treeview = this.$refs.treeview;
      let updatedChildTreeData = {};
      for (var i = 0; i < childTree.length; i++) {
        //gets all projects from current level
        const nodeData = treeview.getTreeData(childTree[i].key)[0];
        updatedChildTreeData[childTree[i].key] = {
          order: i,
          name: childTree[i].name,
          parentKey: parentCatKey,
          key: childTree[i].key,
          modified: childTree[i].modified,
          created: childTree[i].created,
          open: nodeData?.open || false,
        };

        if (childTree[i].children && childTree[i].children.length > 0) {
          for (let j = 0; j < childTree[i].children.length; j++) {
            //get children keys
            const nestedChildrenData = this.getNestedUpdatedChildrenData(
              childTree[i].children,
              childTree[i].key
            );

            updatedChildTreeData = Object.assign(
              updatedChildTreeData,
              nestedChildrenData
            );
          }
        }
      }
      return updatedChildTreeData;
    },
    async treeEdited(event) {
      const clonedTreeData = JSON.parse(JSON.stringify(event.data));
      const rootIndex = clonedTreeData.findIndex((node) => node.key === "root");
      if (rootIndex >= 0) {
        clonedTreeData.splice(rootIndex, 1);
      }
      const data = this.flattenUpdatedTreeData(clonedTreeData);
      let rebuildTree = false;
      let reprocessList = false;
      if (this.renameEnabledByUser) {
        this.renameEnabledByUser = false;
      }
      if (this.nodeReordered) {
        this.nodeReordered = false;
        rebuildTree = true;
        reprocessList = true;
      }

      await getUserNotes().set(data, "directories");
      await this.$nextTick();

      if (rebuildTree) {
        this.createAndSetDirectoryTree(undefined, false, true);
      } else {
        this.setNotesTree(clonedTreeData);
      }

      if (this.disableAfterUpdate) {
        this.allowEditing = false;

        this.disableAfterUpdate = false;
      }

      EventEmitter.emit(REFRESH_DATA_LIST_EVENT, reprocessList);
    },
    nodeOpened() {},
    nodeClosed() {},
    nodeDrag: function (args) {
      this.scrollListForDrag({
        draggedItemEl: args.clonedNode,
        listWrappEl: this.listWrapperEl,
        parentEl: this.parentWrapperEl,
      });
      const { draggedNodeData, droppedNode, draggedNode } = args;
      const draggedNodeId = draggedNode.getAttribute("data-uid");
      if (draggedNodeId === "root") {
        args.dropIndicator = "e-no-drop";
        args.cancel = true;
        return;
      }
      if (droppedNode) {
        const targetId = droppedNode.getAttribute("data-uid");

        if (
          targetId === "root" ||
          targetId === draggedNodeData.id ||
          isChildOrGrandChild(targetId, draggedNodeData.id)
        ) {
          args.cancel = true;
          args.dropIndicator = "e-no-drop";
        }
      }
    },
    checkDragDir(args) {
      const { draggedNodeData, droppedNode } = args;
      if (droppedNode) {
        const targetId = droppedNode.getAttribute("data-uid");
        if (targetId === draggedNodeData.id) {
          args.cancel = true;
          args.dropIndicator = "e-no-drop";
          return;
        }

        if (isChildOrGrandChild(targetId, draggedNodeData.id)) {
          args.cancel = true;
          args.dropIndicator = "e-no-drop";
          return;
        }
      }
    },
    dragStop: async function (args) {
      if (this.dragStarted) {
        this.dragStarted = false;
      }
      const { draggedNodeData, droppedNode } = args;

      const draggedNodeDataId = draggedNodeData.id;
      if (draggedNodeDataId === "root") {
        args.dropIndicator = "e-no-drop";
        args.cancel = true;
        return;
      }

      if (droppedNode) {
        const targetId = droppedNode.getAttribute("data-uid");

        if (
          targetId === "root" ||
          targetId === draggedNodeData.id ||
          isChildOrGrandChild(targetId, draggedNodeData.id)
        ) {
          args.cancel = true;
          args.dropIndicator = "e-no-drop";
          return;
        }
      }

      this.handleNodeDrag(args);
    },
    async handleNoteDrop(event) {
      let targetDirId = "";
      const paths = getPathsFromEvent(event);
      const notes = JSON.parse(event.dataTransfer.getData("text"));
      const updatesList = [];
      const tableUpdates = [];
      for (const path of paths) {
        if (path && path.getAttribute && path.getAttribute("data-dir-id")) {
          targetDirId = path.getAttribute("data-dir-id");
          break;
        }
      }

      if (notes && notes.length) {
        notes.forEach((n) => {
          const { updates, editedData } = createUpdateAndEditedNoteDataByCell({
            fieldName: "path",
            currValue: targetDirId,
            rowId: n.key,
            oldValue: n.path,
            currRowData: n,
          });
          updatesList.push({ updates, editedData, key: n.key });
          tableUpdates.push({
            updates: { ...updates },
            key: n.key,
          });
        });
      }

      addOrRemoveOrUpdateNotesInLocalList({
        notesToUpdate: [...tableUpdates],
      });
      this.updateMultiNotesInNotesList(updatesList);
    },
    async nodeEdited(args) {
      const nodeId = args.nodeData.id;
      let { newText, oldText } = args;
      newText = newText && newText.trim();

      if (newText !== oldText) {
        const selectedDir = this.directories[nodeId];

        if (this.renameEnabledByUser) {
          if (!newText) {
            args.cancel = true;

            this.$swal({
              titleText: "Oops!",
              html: "Folder name cannot be empty",
              icon: "error",
              confirmButtonColor: PRIMARY_COLOR,
              iconColor: PRIMARY_COLOR,
            });
            // this.$swal("Oops!", "Folder name cannot be empty", "error");
            return;
          }

          const currTree = this.notesTree;
          let folderExists = true;
          if (selectedDir.parentKey) {
            const parentDir = findDir(
              this.notesTree,
              (node) => node.key === selectedDir.parentKey
            );
            if (parentDir.children && parentDir.children.length) {
              folderExists = parentDir.children.some(
                (c) => newText === c.name.trim()
              );
            }
          } else {
            folderExists = currTree.some((c) => c.name.trim() === newText);
          }
          if (folderExists) {
            args.cancel = true;

            this.$swal({
              titleText: "Oops!",
              html: "Cannot rename the folder because a same folder already exists!",
              icon: "error",
              confirmButtonColor: PRIMARY_COLOR,
              iconColor: PRIMARY_COLOR,
            });
            return;
          }
          const { editedData } = createDirUpdateAndEditedData(
            {
              name: newText,
            },
            selectedDir
          );

          storeCatEditAction(
            {
              ...editedData,
              key: selectedDir.key,
            },
            "subjects"
          );
        }

        // await this.updateMultiDir([
        //   { updates, editedData, key: selectedDir.key },
        // ]);
        // EventEmitter.emit(FOLDER_NAME_CHANGED_EVENT);
      }
    },
    async handleNodeDrag(args) {
      const { draggedNodeData, dropIndex, droppedNode, position } = args;
      let targetId = "";
      let orderPositions = ["Before", "After"];

      const draggedDirRes = findNodeInTree(
        this.notesTree,
        (node) => node.key === draggedNodeData.id
      );
      let draggedDir = draggedDirRes?.node;

      if (!isEmpty(draggedDir)) {
        if (droppedNode) {
          targetId = droppedNode.getAttribute("data-uid");
        }

        let updatedItem = {
          ...draggedDir,
          order: typeof dropIndex === "number" ? dropIndex : 0,
        };

        // if()
        const currItemData = cloneDeep(draggedDir);

        if (targetId && this.directories[targetId]) {
          const targteItemRes = findNodeInTree(
            this.notesTree,
            (node) => node.key === targetId
          );

          const targetItemData = targteItemRes?.node;
          if (position && position === "Inside") {
            if (!isEmpty(targetItemData)) {
              draggedDir = {
                ...draggedDir,
                parentKey: targetItemData.key,
              };
              let itemChildren = targetItemData.children;
              if (itemChildren && itemChildren.length) {
                updatedItem = {
                  ...draggedDir,
                  order: itemChildren.length,
                };
              } else {
                updatedItem = { ...draggedDir, order: 0 };
              }
            }
          }

          if (position && orderPositions.indexOf(position) >= 0) {
            if (!isEmpty(targetItemData)) {
              const draggedCateParentKey = targetItemData.parentKey;
              // let parentChildren = [];

              if (typeof dropIndex === "number") {
                draggedDir = {
                  ...draggedDir,
                  order: dropIndex,
                  parentKey: draggedCateParentKey || "",
                };

                updatedItem = { ...draggedDir, order: dropIndex };
              }
            }
          }
        } else {
          draggedDir = {
            ...draggedDir,
            order: dropIndex,
            parentKey: "",
          };

          updatedItem = { ...draggedDir, order: dropIndex };
        }

        const { editedData } = createDirUpdateAndEditedData(
          { order: updatedItem.order, parentKey: updatedItem.parentKey || "" },
          this.directories[currItemData.key]
        );
        if (typeof dropIndex === "number") {
          storeCatReorderOrMoveAction(editedData, "subjects");
          this.nodeReordered = true;
        }
      }

      // await this.updateMultiDir(updatesList);
    },
    handleNodesData(selectedIds) {
      return new Promise((resolve) => {
        setTimeout(async () => {
          this.clearNoteTopicsSelection();
          this.updateRootState({
            currViewData: {},
            itemInspectEnabled: false,
          });
          await getUserNotes().set(selectedIds, "selectedFolders");

          resolve();
        }, 240);
      });

      // this.setFoldersListFilter(finalIds);
    },
    nodeclicked(args) {
      const event = args.event;
      if (hasParentEl(event.target, { className: ["edit-item-btn"] })) {
        return;
      }
      const selectedIDs =
        document.getElementById("directory-tree").ej2_instances[0]
          .selectedNodes;

      if (event.detail === 1) {
        this.selectNodeFuncId = setTimeout(() => {
          this.handleNodesData(selectedIDs);
        }, 200);
      }

      // this.changeView("1");
    },
    nodeSelected() {},
    nodeCollapsed(args) {
      const nodeId = args.nodeData.id;

      if (nodeId && this.directories[nodeId]) {
        this.updateMultiDir(
          [{ updates: { open: false }, editedData: {}, key: nodeId }],
          false
        );
      }
    },
    nodeExpanded(args) {
      const nodeId = args.nodeData.id;

      if (nodeId && this.directories[nodeId]) {
        this.updateMultiDir(
          [{ updates: { open: true }, editedData: {}, key: nodeId }],
          false
        );
      }
    },
    ...mapActions("note", [
      "setNotesTreeStructure",
      "clearNoteTopicsSelection",
    ]),
    ...mapActions(["updateRootState"]),
  },
  mounted() {
    this.parentWrapperEl = this.$el.querySelector("#directory-tree-wrapper");
    this.listWrapperEl = this.$el.querySelector("#directory-tree");
    this.init();
  },
  beforeDestroy() {
    this.removeListeners();
  },
  computed: {
    ...mapGetters("note", [
      "notes",
      "directories",
      "notesTree",
      "selectedFolders",
      "dirChildrenMap",
      "rawNotes",
    ]),
  },
  watch: {
    directories: {
      handler(n, o) {
        const newKeys = Object.keys(n);
        const oldKeys = Object.keys(o);
        if (newKeys.length !== oldKeys.length) {
          this.createAndSetDirectoryTree(undefined, undefined, false);
        }
      },
    },
    rawNotes: {
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.createAndSetDirectoryTree(undefined, false);
        }
      },
    },
    selectedFolders: {
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.setSelectedFolders();
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.directoryTree .e-level-1 {
  margin-left: -24px;
}

.e-drag-item.e-treeview.directoryTree .item-count {
  margin-left: 15px;
}

.e-name .subcategory {
  margin-left: 20px;
}

.selectedTreeView {
  text-decoration: underline;
  border: none !important;
  color: red;
}
.e-list-text {
  width: 100%;
}

.e-list-parent.e-ul {
  overflow: hidden;
}

/* span.sub-dir:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #747474;
} */
.e-treeview .e-list-text {
  padding: 0 5px 0 0;
}
.e-treeview .e-list-item .e-ul {
  padding: 0 0 0 12px;
}

/* #treeview {
  height: 100%;
  overflow-y: scroll;
} */

#directory-tree-wrapper {
  height: 100%;
  overflow-y: scroll;
}
#directory-tree-side-bar {
  height: 100%;
}

#directory-tree {
  /* height: 100%; */
}
</style>
