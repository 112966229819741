<template>
  <div class="topic-selector-wrapper">
    <NotesFolderSelector
      ref="select"
      :value="selectedVal"
      @change="handleChange"
      @blur="handleBlur"
      @focus="handleFocus"
      @keydown="triggerKeyDown"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  columnPropsMixin,
  selectorColumnPropsMixin,
} from "@/core/components/DataTable";
import notesHelpersMixin from "@/mixins/notesHelpersMixin";
// import { createDirectoryOpts } from "@/helpers/directories";
import cloneDeep from "lodash/cloneDeep";
import { createNoteDataForAdd } from "@/helpers/notes";
import { isReverseTabKeys } from "@/utils/hotKeys";
import NotesFolderSelector from "@/components/EditComponents/NoteFolderSelector/NoteFolderSelector.vue";
export default {
  components: {
    NotesFolderSelector,
  },
  mixins: [columnPropsMixin, selectorColumnPropsMixin, notesHelpersMixin],
  computed: {
    ...mapGetters("note", ["notes", "directories"]),
  },
  data() {
    return {
      selectedVal: "",
    };
  },
  methods: {
    open() {
      this.$refs.select.openInput();
    },
    handleChange(val) {
      this.onSuccess(val, val, this.cell);
      this.selectedVal = val;
    },
    handleKeyDown(e) {
      const keyCode = e.which !== undefined ? e.which : e.keyCode;
      if (isReverseTabKeys(e)) return;
      const currentCell = this.$el.parentNode.parentNode;

      if (keyCode === 9 && currentCell) {
        e.stopPropagation();
        e.preventDefault();
        const currData = cloneDeep(this.cell.getData());
        const currentCell = this.$el.parentNode.parentNode;
        const isInAddMode = this.cell.api.isAddModeEnabled();

        let callEndEdit = true;

        let el = currentCell.parentNode
          .querySelector(".itemType-cell")
          .querySelector("input");

        if (isInAddMode) {
          if (currData.itemType && currData.itemType === "task") {
            this.addNoteAsTask(currData);
            return;
          }
          const noteData = createNoteDataForAdd(
            {
              title: currData.title,
              descr: currData.descr,
              path: currData.path,
            },
            this.notes,
            this.directories
          );

          this.addMultiNotesInNotesList([noteData]);
          this.cell.api.triggerAddRow(noteData);
        } else {
          setTimeout(() => {
            if (callEndEdit) {
              this.cell.api.endEditing();
            } else {
              el?.focus();
            }
          });
        }
      }
    },
    triggerKeyDown(e) {
      setTimeout(() => {
        this.handleKeyDown(e);
      }, 0);
    },
  },
  watch: {
    "data.path": {
      handler(newVal) {
        this.selectedVal = newVal;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.topic-selector-wrapper >>> .opt-selector-input {
  height: 33px;
  margin-top: 0;
  font-size: 13px;
  padding-top: 0;
  margin-bottom: 2px;
}
.topic-selector-wrapper >>> .opt-selector-input .v-input__control {
  height: 33px;
}
.topic-selector-wrapper >>> .opt-selector-input .v-input__slot {
  height: 33px;
  /* border: 1px solid #000; */
}

/* .selection-container {
  display: inline-flex;
} */

.opt-selector-input .v-input__slot {
  height: 33px;
  /* border: 1px solid #000; */
}

.opt-selector-input .v-select__slot {
  height: 33px;
}

.opt-selector-input .v-select__selections {
  height: 33px;
}
</style>
