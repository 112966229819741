<template>
  <ItemTypeSelector
    :value="noteType"
    @input="handleChange"
    @blur="handleBlur"
    @focus="handleFocus"
    @keydown="triggerKeyDown"
    type="note"
    ref="select"
    :optItemIconColor="greyIconColor"
    :selectedOptIconColor="greyIconColor"
  />
</template>
<script>
import ItemTypeSelector from "@/components/ItemTypeSelector";
import { mapActions } from "vuex";
import {
  columnPropsMixin,
  selectorColumnPropsMixin,
} from "@/core/components/DataTable";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import notesHelpersMixin from "@/mixins/notesHelpersMixin";
import {
  // DARK_GREY_ICON_COLOR,
  NEW_DARK_ICON_COLOR,
} from "@/variables/colors";
export default {
  mixins: [
    columnPropsMixin,
    selectorColumnPropsMixin,
    notesHelpersMixin,
    tasksHelpersMixin,
  ],
  components: {
    ItemTypeSelector,
  },
  data() {
    return {
      type: "note",
    };
  },
  computed: {
    noteType() {
      return this.data.itemType || "note";
    },
    greyIconColor() {
      return NEW_DARK_ICON_COLOR;
    },
  },
  methods: {
    ...mapActions("convertModeData", ["setDataForConvertMode"]),
    handleChange(val) {
      const isAddModeEnabled = this.cell.api.isAddModeEnabled();
      if (!isAddModeEnabled && val === "task") {
        this.setDataForConvertMode({
          dataList: [{ ...this.data }],
          typeToUse: "task",
        });
        return;
      }

      this.onSuccess(val, val, this.cell);
    },

    async handleNoteToTaskChange() {
      this.convertNotesToTasks({ ...this.data });
    },
    handleFocus() {
      this.$refs.select.openInput();
    },
  },
};
</script>

<style scoped></style>
