<template>
  <ViewLayout name="Notes" :noUser="false" :loading="false" ref="viewWrapper">
    <template v-slot:side-nav-view>
      <NotesCateTree ref="sideCompWrapper" />
    </template>
    <template v-slot:main-view>
      <NotesList :showTable="!loading" ref="tableWrapper" />
    </template>
  </ViewLayout>
</template>

<script>
import Vue from "vue";
import firebase from "@/services/firebase";

import ViewLayout from "@/layouts/ViewLayout.vue";
import DatabaseInterface from "../services/DatabaseInterface";
import EventEmitter from "@/helpers/eventEmitter";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isEqual } from "lodash";
import { createUserActionsHistory } from "../helpers/actionHistory";
import { getAppColorThemeFromUserData } from "@/helpers/app";
import handleUserActionHelperMixin from "@/mixins/handleUserActionHelperMixin";
import NotesCateTree from "@/components/NotesCateTree/NotesCateTree.vue";
import NotesList from "@/components/NotesListView";
import { initUserNotes, processNotes, notesCollection } from "@/helpers/notes";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import isEmpty from "lodash/isEmpty";
import { initUserResources, resourcesCollection } from "@/helpers/resources";
import globalSearchBoxHelperMixin from "@/mixins/globalSearchBoxHelperMixin";
import { SET_ID_FOR_SCROLL } from "@/variables/events";
import viewHelpersMixin from "@/mixins/viewHelpersMixin";
export default {
  mixins: [
    userDetailsMixin,
    handleUserActionHelperMixin,
    globalSearchBoxHelperMixin,
    viewHelpersMixin,
  ],
  components: {
    NotesCateTree,
    ViewLayout,
    NotesList,
  },
  data: () => ({
    noUser: true,
    loading: true,
    showTaskBar: false,
    dataLoaded: false,
  }),
  methods: {
    clearTasksState() {
      this.updateState({
        rawTasks: [],
        rawTasksMap: {},
        tasks: [],
      });
    },
    async handleTasksChanges(snapshot) {
      const res = snapshot.val();
      const rootStateUpdates = {};
      const taskUpdates = {};

      const currResourceSettings = this.resourceSettings;
      const currTaskSettings = this.taskSettings;
      const updatedTaskSettings = res.taskSettings || {};
      const updatedResourceSettings = res.resourceSettings || {};
      const currRawTasksMap = this.rawTasksMap;
      const currRawProjects = this.projects;
      const currRawCategories = this.categories;
      const updatedRawTasksMap = res.tmp || {};
      const updatedCategories = res.categories || {};
      const updatedProjects = res.projects || {};
      if (!isEqual(currRawTasksMap, updatedRawTasksMap)) {
        taskUpdates.rawTasks = Object.values(res.tasks);
        taskUpdates.rawTasksMap = res.tasks;
      }

      if (!isEqual(currRawCategories, updatedCategories)) {
        taskUpdates.categories = updatedCategories;
      }

      if (!isEqual(currRawProjects, updatedProjects)) {
        taskUpdates.projects = updatedProjects;
      }

      if (!isEqual(currResourceSettings, updatedResourceSettings)) {
        rootStateUpdates.resourceSettings = updatedResourceSettings;
      }

      if (!isEqual(currTaskSettings, updatedTaskSettings)) {
        rootStateUpdates.taskSettings = updatedTaskSettings;
      }

      rootStateUpdates.selectedColorTheme = getAppColorThemeFromUserData(res);
      this.updateState(taskUpdates);
      this.updateRootState({
        ...rootStateUpdates,
      });
    },
    init() {
      this.setLoader(true);
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          initUserResources(user.uid);
          this.setUserDetails(user);
          this.noUser = false;
          this.showTaskBar = true;
          createUserActionsHistory(user.uid);
          initUserNotes(user.uid);
          this.setTasksListener(user.uid);
          this.setUserActionListener();
          this.checkForGoogleCalSync(user.uid);
          this.resourcesListener = resourcesCollection.dbStore
            .doc(user.uid)
            .onSnapshot(this.handleResourcesUpdates);
          this.notesListener = notesCollection.dbStore
            .doc(user.uid)
            .onSnapshot(this.handleNotesUpdates);
        } else {
          this.setLoader(false);
          document.location.href = "/";
        }
      });
    },
    setTasksListener(userId) {
      DatabaseInterface.get(userId).on("value", this.handleTasksChanges);
    },
    async checkForGoogleCalSync(userId) {
      const res = await (
        await DatabaseInterface.get(userId).once("value")
      ).val();

      this.updateRootState({
        isGoogleCalSyncEnabled: res.googleCalSyncInfo?.isEnabled || false,
      });
    },
    handleNotesUpdates(doc) {
      this.setLoader(false);
      const data = doc.data();
      const currNotes = this.notes;
      const currNewRawNotes = data.notes;
      const currRawNotes = this.rawNotes;
      const currDirectories = this.directories;
      const newDirectoriesData = data?.directories || {};
      // const currRaw
      const updates = {
        selectedFolders: data.selectedFolders || [],
        collapsedGroupsList: data.collapsedGroups || [],
      };

      if (!isEqual(currDirectories, newDirectoriesData)) {
        updates.directories = newDirectoriesData;
      }

      if (!isEqual(currNewRawNotes, currRawNotes)) {
        if (isEmpty(currNotes) && !isEmpty(currNewRawNotes)) {
          updates.notes = processNotes(data.notes, data.directories);
        }

        updates.rawNotes = currNewRawNotes;
      }
      this.updateNoteState(updates);
      this.loading = false;

      if (!this.dataLoaded) {
        this.dataLoaded = true;
      }
    },
    handleResourcesUpdates(doc) {
      const data = doc.data();
      const currNewRawResources = data?.resources || {};
      const currRawResources = this.rawResourcesMap;

      const currResourcesCategories = this.resourcesCategories;
      const newResourcesCategories = data?.categories || {};
      const updates = {};

      if (!isEqual(currNewRawResources, currRawResources)) {
        updates.rawResourcesMap = currNewRawResources;
      }
      if (!isEqual(newResourcesCategories, currResourcesCategories)) {
        updates.categories = newResourcesCategories;
      }
      this.updateResourcesState(updates);
    },
    openInbox() {
      DatabaseInterface.update(
        {
          inboxView: true,
        },
        this.userInfo.uid
      );
      this.$router.replace({
        name: "Dashboard",
      });
    },
    handleMenuClick(menuItem) {
      if (menuItem.index !== 2) {
        DatabaseInterface.update(
          {
            notesView: false,
            nav: menuItem.index,
          },
          this.userInfo.uid
        );
        this.$router.replace({
          name: "Dashboard",
        });
      }
    },
    removeResourcesListener() {
      if (this.resourcesListener) {
        this.resourcesListener();
      }
    },
    removeListeners() {
      if (this.notesListener) {
        this.notesListener();
      }
      this.removeResourcesListener();
    },
    clearResourcesState() {
      this.updateResourcesState({
        rawResourcesMap: {},
        processedResources: [],
      });
    },
    async openNoteData() {
      await Vue.nextTick();
      const queryData = this.$route.query;
      if (!isEmpty(queryData)) {
        const noteId = queryData.noteId;
        if (noteId) {
          const noteData = this.notes.find((n) => n.key === noteId);
          if (!isEmpty(noteData)) {
            this.setDataForNoteEdit({
              dataToSet: { ...noteData },
            });
          }

          this.$router.replace(this.$route.path);
        }
      }
    },
    async handleReturnToView() {
      await Vue.nextTick();

      const viewConfig = this.$route.params.viewConfig;
      let resetPath = false;
      // let closeLoader = true;

      if (!isEmpty(viewConfig)) {
        resetPath = isEmpty(this.$route.query);

        this.handleNotesViewConfig(viewConfig);
        // const { openForm, itemIdToOpen, itemIdToSelect } = viewConfig;

        // if (!openForm && itemIdToSelect) {
        //   const noteIdToSelect = this.notes.find(
        //     (n) => n.key === itemIdToSelect
        //   )?.key;

        //   if (noteIdToSelect) {
        //     EventEmitter.emit(SET_ID_FOR_SCROLL, noteIdToSelect);
        //   }
        // }
        // if (openForm && itemIdToOpen) {
        //   closeLoader = false;
        //   const noteData = this.notes.find((n) => n.key === itemIdToOpen);

        //   await Vue.nextTick();
        //   setTimeout(() => {
        //     if (!isEmpty(noteData)) {
        //       this.setDataForNoteEdit({
        //         dataToSet: { ...noteData },
        //       });
        //     }
        //   }, 0);
        // }
      }

      // if (closeLoader) {
      //   this.loaderToggler(false);
      // }

      await Vue.nextTick();
      if (resetPath) {
        this.$router.replace(this.$route.path);
      }
    },
    ...mapActions({
      loaderToggler: "toggleLoader",
    }),
    ...mapActions("editForm", ["setDataForNoteEdit"]),
    ...mapActions("user", ["setUserDetails"]),
    ...mapActions("note", ["updateNoteState"]),
    ...mapMutations("task", ["updateState"]),
    ...mapMutations(["setLoader"]),
    ...mapActions(["updateRootState"]),
    ...mapActions("resourcesData", ["updateResourcesState"]),
  },
  computed: {
    ...mapGetters("task", ["rawTasks", "categories", "projects"]),
    ...mapGetters("note", ["notes", "rawNotes", "directories"]),
    ...mapGetters(["resourceSettings", "taskSettings"]),
    ...mapGetters("resourcesData", {
      rawResourcesMap: "rawResourcesMap",
      resourcesCategories: "categories",
    }),
  },
  created() {
    this.dataLoaded = false;
    this.updateNoteState({
      rawNotes: [],
      directories: {},
    });
  },
  mounted() {
    this.init();
    EventEmitter.on("unsubscribe", this.removeListeners);
  },
  beforeDestroy() {
    this.clearTasksState();
    this.clearResourcesState();
    this.removeUserActionListener();
    this.removeResourcesListener();
    DatabaseInterface.get(this.userInfo.uid).off("value");
    this.removeListeners();
    EventEmitter.off("unsubscribe", this.removeListeners);
  },
  watch: {
    dataLoaded(n) {
      if (n) {
        this.handleReturnToView();
        this.openNoteData();
      }
    },
  },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9f9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e1e1e1;
}

.width-fixed {
  width: 280px;
}

.el-fixed {
  position: fixed;
}

.mode-select-bar {
  background-color: #eeeeee;
  top: 36px;
  padding-right: 0px !important;
}

.calender-view {
  /* top: 120px;
  bottom: 8px; */
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.reset-calender-top {
  top: 80px;
  padding-top: 10px;
}

.v-btn--active >>> svg {
  fill: rgb(255, 152, 0) !important;
}

.side-nav {
  width: 100%;
  max-width: 280px;
  position: relative;
  height: 100%;
  background-color: #f9f9f9;
}

.side-nav .side-nav-content {
  width: 100%;
  max-width: 280px;
  position: fixed;
}

.page-layout aside {
  min-width: 280px;
  max-width: 280px;
}

.sidebar-title {
  font-size: 1.3845rem;
  padding: 2px;
}
.main {
  width: 100%;
}

.directory-tree {
  top: 120px;
}

.v-btn--active >>> .v-icon {
  color: var(--primary-color) !important;
}
</style>
