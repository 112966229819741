<template>
  <div class="actions-cell-wrapper">
    <EditBtn
      cssClass="edit-note-btn"
      @click="editNote"
      :btnText="editPenLabel"
      :btnIconColor="greyIconColor"
    />
  </div>
</template>
<script>
import EditBtn from "@/components/GridComponents/EditBtn";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import notesHelpersMixin from "@/mixins/notesHelpersMixin";
import { columnPropsMixin } from "@/core/components/DataTable";
import { mapActions } from "vuex";
import {
  // DARK_GREY_ICON_COLOR,
  NEW_DARK_ICON_COLOR,
} from "@/variables/colors";
export default {
  components: {
    EditBtn,
  },
  mixins: [userDetailsMixin, notesHelpersMixin, columnPropsMixin],
  props: {},
  methods: {
    ...mapActions("editForm", ["setDataForNoteEdit"]),
    editNote(e) {
      e.stopPropagation();
      this.setDataForNoteEdit({
        dataToSet: this.data,
      });
    },
  },
  computed: {
    greyIconColor() {
      return NEW_DARK_ICON_COLOR;
    },
    editPenLabel() {
      return "Edit Note";
    },
  },
};
</script>
<style scoped>
.hover-icon {
  display: none;
}

.task-action-btn >>> svg {
  fill: #000;
}
.task-action-btn:hover .hover-icon {
  display: inline-flex;
}
.task-action-btn:hover .main-icon {
  display: none;
}

.edit-note-btn {
  display: none;
}
</style>
